<script lang='ts'>
  import { saveActivity } from '@/helpers/game'
  import { romanization } from '@/helpers/romanize'
  import { formatWordProps } from '@/helpers/wordProps'

  import AudioPlayer from '@/components/audio/AudioPlayer.svelte'

  import { _ } from '@/libs/i18n'

  export let currentWord: any = {},
    iso: string,
    props: any = {}
  const useRomanization = ['srp', 'cmn'].includes(iso)
</script>

<div class='word-block'>
  <div class='original-word _row'>
    <AudioPlayer
      onPlay={() => { saveActivity(['lesson-word-audio', '', currentWord.id]) }}
      src={currentWord?.audio}
      variant='middle'
    />
    {#if useRomanization}
      <ruby class='word'>
        {formatWordProps(currentWord, iso, props)}
        <rt>{romanization(currentWord, iso)}</rt>
      </ruby>
    {:else}
      <strong class='word'>{formatWordProps(currentWord, iso, props)}</strong>
    {/if}
  </div>
  <div class='word-translation'>
    <div>{currentWord.translation || ''}</div>
    <div class='pos'>{currentWord.id_pos ? $_('pos.' + currentWord.id_pos) : ''}</div>
  </div>
</div>

<style lang='scss'>
  .word-block {
    display: flex;
    flex-direction: column;
  }

  .original-word {
    font: var(--Semibold-600);
    color: var(--Gray-Darker);

    > .word {
      padding: 0.6rem 0.8rem;
    }
  }

  .word-translation {
    display: flex;
    gap: 0.8rem;
    align-items: baseline;
    padding: 0.6rem 0 0 5.6rem;
    font: var(--Medium-400);

    > .pos {
      font: var(--Regular-200);
    }
  }

  @media(max-width: 768px) {
    .word-translation {
      padding-left: 1.6rem;
    }
  }
</style>
