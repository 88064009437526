<script lang='ts'>
  import { createEventDispatcher } from 'svelte'

  import { fetchData } from '@/helpers/fetchHelpers'
  import { _ } from '@/helpers/i18n'

  import Icon from '@/components/icons/Icon.svelte'
  import Overlay from '@/components/ui/Overlay.svelte'

  export let idWord: number,
    mnemo: string,
    kebabItem = false, // If we want to have Report icon with text (example: for mobile view inside kebab select list) we have to pass this boolean paramether from parent component
    onSave = (idWord: number, data: { mnemo: string }) => {}
  const dispatch = createEventDispatcher()

  let edit = false,
    inputField: HTMLTextAreaElement

  function toggleEdit () {
    edit = !edit

    setTimeout(() => {
      if (edit && inputField) inputField.focus()
    })
  }

  async function callSaveMnemoApi () {
    await fetchData('learn/lessonSaveMnemo', { mnemo, wordId: idWord })
    dispatch('mnemo-edited', { idWord, mnemo })
    edit = false
  }

  function preventEnter (e: KeyboardEvent) {
    if (e.key === 'Enter') {
      callSaveMnemoApi()
      if (onSave) onSave(idWord, { mnemo })
    }
  }
</script>
{#if idWord}
  {#if !edit}
    <div
      class='note-icon'
      class:-hasNote={mnemo}
      role='presentation'
      title={mnemo? $_('learnPage.viewNote') : $_('learnPage.addNote')}
      on:keypress={() => {}}
      on:click={toggleEdit}
    >
      <span class='_icon -noBorder'><Icon icon='NotePencil' /></span>
      {#if mnemo}
        <span class='note'>{kebabItem ? $_('notes.viewNote') : ''}</span>
      {:else}
        <span class='note'>{kebabItem ? $_('notes.addNote') : ''}</span>
      {/if}
    </div>
  {:else}
    <div class='mnemo-wrapper'>
      <Overlay borderCloseButton onClick={() => {edit = false}}>
        <h3 slot='heading'>{$_('notes.addNote')}</h3>
        <div class='_vertical-small'>
          <h5>{$_('notes.message')}</h5>
          <textarea
            bind:this={inputField}
            class='mnemo-input'
            placeholder='Type here'
            rows='7'
            bind:value={mnemo}
            on:keydown={preventEnter}
          />
          <button class='_maxwidth-btn' type='button' on:click={callSaveMnemoApi}>{$_('mix.save')}</button>
        </div>
      </Overlay>
    </div>
  {/if}
{/if}

<style lang='scss'>
  .note-icon {
    &.-hasNote {
      > span {
        color: var(--Green-Medium);
      }
    }

    > .note {
      display: none;
    }
  }

  .mnemo-input {
    width: 100%;
    min-height: 2rem;
    margin: 0;
  }

  .mnemo-wrapper {
    > :global(.overlay-wrapper .content) {
      width: 58.4rem;
    }
  }

  @media(max-width: 768px) {
    .note-icon {
      display: flex;
      align-items: center;
      width: 100%;
      cursor: pointer;

      > .note {
        display: block;
        white-space: nowrap;
      }
    }

    .mnemo-wrapper {
      > :global(.overlay-wrapper .content) {
        width: 35rem;
      }
    }
  }

</style>
