<script lang='ts'>
  import { saveActivity } from '@/helpers/game'

  import AudioPlayer from '@/components/audio/AudioPlayer.svelte'
  import Accordion from '@/components/ui/Accordion.svelte'

  import { _ } from '@/libs/i18n'

  export let synonyms: any[]
  export let currentWord: any
  export let type: 'synonyms' | 'homonyms' = 'synonyms'
  export let loadWord: (id: number) => void
</script>
{#if synonyms?.length > 1}
  <Accordion show>
    <div
      slot='header'
      role='button'
      tabindex='0'
      on:click={() => saveActivity(['we-synonyms'])}
      on:keypress={() => {}}
    >
      {type === 'homonyms' ? $_('learnPage.homonyms') : $_('learnPage.synonyms')}
    </div>
    <div slot='content'>
      {#each synonyms as hom}
        {#if hom.id !== currentWord.id}
          <div class='homonyms-row'>
            <AudioPlayer src={hom.audio} variant='mini' />
            <div
              class='loadWordBlock'
              class:-current={hom.id === currentWord.id}
              role='button'
              tabindex='0'
              on:click={() => loadWord(hom.id)}
              on:keypress={() => {}}
            >
              <span class='word'>{type === 'homonyms' ? hom.word + ' :: ' + currentWord.translation : hom.word}</span>
              <small>{$_('pos.' + hom.pos)}</small>
            </div>
          </div>
        {/if}
      {/each}
    </div>
  </Accordion>
{/if}
<style lang='scss'>
  .homonyms-row {
    display: flex;
    align-items: center;

    > .loadWordBlock {
      cursor: pointer;

      &.-current {
        font-weight: bold;
        color: var(--color-error);
      }

      > .word {
        color: var(--text-primary-color);
      }

      &:hover {
        color: var(--Primary-Medium);

        > .word {
          color: var(--Primary-Medium);
        }
      }
    }
  }
</style>
