<script lang='ts'>
  import { removePostSyntax } from '@/helpers/removePostSyntax'

  import AudioPlayer from '@/components/audio/AudioPlayer.svelte'

  import { ExampleSentences } from '@/definitions/langoid'
  import { _ } from '@/libs/i18n'

  export let sentences: ExampleSentences[] = []
  export let showTitle = false
</script>
{#if sentences?.length}
  <div class='example-sentences'>
    {#if showTitle}
      <h6 class='titleItalic'>{$_('tools.exampleSentences')}:</h6>
    {/if}
    {#each sentences as sentence}
      <div class='each-example-wrapper'>
        <div class='original-example _row'>
          <div class='audio'>
            {#if sentence.audio}
              <AudioPlayer src={sentence.audio} variant='normal' />
            {/if}
          </div>

          <div class='content'>{removePostSyntax(sentence.original || sentence.content)}</div>
        </div>
        {#if sentence.translation}
          <div class='translation'>
            <div class='audio' />
            <div class='content'>{removePostSyntax(sentence.translation)}</div>
          </div>
        {/if}
      </div>
    {/each}
  </div>
{/if}
<style lang='scss'>
  .example-sentences {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    align-items: flex-start;
  }

  .each-example-wrapper {
    display: flex;
    flex-direction: column;

    > .translation {
      padding-left: 4.8rem;

      > .content {
        padding: 0.6rem 0.8rem;
        font: var(--Medium-400);
        color: var(--text-tertiary-color);
      }
    }
  }

  .original-example {
    > .audio {
      min-width: 4rem;
    }

    > .content {
      padding: 0.6rem 0.8rem;
      font: var(--Semibold-600);
      color: var(--text-secondary-color);
    }
  }

  @media (max-width: 768px) {
    .example-sentences {
      gap: 1.6rem;
    }

    .each-example-wrapper {
      padding: 0;
      font-size: 1.2rem;

      > .translation > .content {
        padding: 0.6rem 1.6rem;
      }
    }
  }
</style>
