<script lang='ts'>
  import { saveActivity } from '@/helpers/game'
  import { romanization } from '@/helpers/romanize'

  import AudioPlayer from '@/components/audio/AudioPlayer.svelte'
  import FakeButton from '@/components/ui/FakeButton.svelte'

  import { LessonWord, WordsTagged } from '@/definitions/langoid'

  export let words: WordsTagged[] = [],
    lessonWords: Record<number, LessonWord> = [],
    selected = 0,
    onClickOnWord = (selected: number) => {},
    currentSentence: any = {},
    type = 'lesson',
    iso = ''

  const useRomanization = ['srp', 'cmn'].includes(iso)

  const handleOnClick = (index: number) => {
    if (words[index].id > 0 && (words[index].isWord || type === 'exploreWords')) {
      onClickOnWord(index)
    }
  }

  const createClassName = (word: WordsTagged, index: number, selected: number) => {
    let isWord, learningStatus
    const classList = [`index-${index}`]
    if (type === 'exploreWords') {
      isWord = true
      learningStatus = word.group
    } else {
      isWord = word.isWord
      learningStatus = word.learningStatus
    }

    if (isWord) {
      classList.push('single-word')
      if (word.id_word === 0) classList.push('-properNoun')
      if (learningStatus) classList.push(learningStatus)
      if (index === selected) classList.push('-selected')
    } else {
      classList.push('non-word')
    }

    return classList.join(' ')
  }
</script>

<div class='sentence-container -{type}'>
  {#if Object.keys(currentSentence).length > 0}
    <AudioPlayer
      onPlay={() => { saveActivity(['lesson-sentence-audio', '', currentSentence.id]) }}
      src={currentSentence.audio}
      variant='big'
    />
  {/if}
  <div class='sentenceWords'>
    {#each words as word, index}
      <FakeButton onClick={() => handleOnClick(index)}>
          <span class={createClassName(word, index, selected)}>
            {#if (word.isWord || type === 'exploreWords') && useRomanization}
              <ruby>
                {word.content}
                {#if lessonWords?.[word.id]?.romanization}
                  <rt>{romanization(lessonWords[word.id], iso)}</rt>
                {/if}
              </ruby>
            {:else}
              {word.content}
            {/if}
          </span>
      </FakeButton>
    {/each}
  </div>
</div>

<style lang='scss'>
  .sentence-container {
    display: flex;
    gap: 1.6rem;

    &.-exploreWords {
      display: flex;
    }

    > .sentenceWords {
      display: flex;
      flex-wrap: wrap;
      gap: 0.2rem;
      align-items: center;
      height: fit-content;
    }
  }

  .single-word {
    display: inline-block;
    padding: 0.4rem 0.8rem;
    border: solid var(--Base-White) 2px;
    border-radius: 0.7rem;
    box-shadow: var(--Shadow-small);
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    &.L0 {
      border-color: var(--seen);
    }

    &.L1 {
      border-color: var(--starting);
    }

    &.L2 {
      border-color: var(--good);
    }

    &.L3 {
      border-color: var(--know);
    }

    &.-selected {
      border: solid var(--Base-Black) 2px;

      &.L0 {
        border-color: var(--Gray-Dark);
      }

      &.L1 {
        border-color: var(--Rose-Dark);
      }

      &.L2 {
        border-color: var(--Cyan-Dark);
      }

      &.L3 {
        border-color: var(--Green-Dark);
      }
    }

    &.-properNoun {
      font-style: italic;
      background-color: var(--Error-Light);
      cursor: default;
    }
  }

  @media (max-width: 768px) {
    .sentence-container {
      justify-content: left;
      font: var(--Regular-500);
    }
  }
</style>
