<script lang='ts'>
  import { _ } from '@/helpers/i18n'

  import Icon from '@/components/icons/Icon.svelte'
  import FakeButton from '@/components/ui/FakeButton.svelte'

  const noop = () => {
  }

  export let prevSentence = noop,
    nextSentence = noop,
    prevWord = noop,
    nextWord = noop,
    isFinished = false,
    onClickOnFinish = noop
</script>
<div class='sticky-controller'>
  {#if onClickOnFinish && isFinished}
    <button class='_warning-btn' type='button' on:click={onClickOnFinish}>{$_('mix.finish')}</button>
  {/if}
  <slot name='nextLesson' />
  <div class='controller-container'>
    <div class='leftButton'>
      <FakeButton class='_icon -bigBorder' onClick={prevSentence}>
        <Icon icon='CaretDoubleLeft' weight='thin' />
      </FakeButton>
      <FakeButton class='_icon -bigBorder' onClick={prevWord}>
        <Icon icon='CaretLeft' weight='thin' />
      </FakeButton>
    </div>
    <div class='rightButton'>
      <FakeButton class='_icon -bigBorder' onClick={nextWord}>
        <Icon icon='CaretRight' weight='thin' />
      </FakeButton>
      <FakeButton class='_icon -bigBorder' onClick={nextSentence}>
        <Icon icon='CaretDoubleRight' weight='thin' />
      </FakeButton>
    </div>
  </div>
</div>
<style lang='scss'>
  .sticky-controller {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }

  .controller-container {
    display: flex;
    justify-content: space-between;
    min-height: 3rem;

    > .leftButton, > .rightButton {
      display: flex;
      gap: 1rem;
    }
  }

  @media (max-width: 768px) {
    .sticky-controller {
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      padding: 0.8rem;
      background-color: var(--Base-White);
      box-shadow: var(--Shadow-L);
    }
  }
</style>
