<script lang='ts'>
  import { saveActivity } from '@/helpers/game'

  import ExampleSentences from '@/components/lesson/ExampleSentences.svelte'
  import Accordion from '@/components/ui/Accordion.svelte'

  import { _ } from '@/libs/i18n'

  export let sentences: any[] = []
</script>
{#if sentences?.length}
  <Accordion show>
    <div
      slot='header'
      role='button'
      tabindex='0'
      on:keypress={() => {}}
      on:click={() => {
              saveActivity(['lesson-examples'])
            }}
    >{$_('learnPage.otherSentences')}</div>
    <div slot='content'>
      <ExampleSentences {sentences} />
    </div>
  </Accordion>
{/if}
