<script lang='ts'>
  import Accordion from '@/components/ui/Accordion.svelte'

  import { LessonWord, WordForms } from '@/definitions/langoid'
  import { _ } from '@/libs/i18n'

  export let wifs: any = []
  export let templates: any = []
  export let currentWord: any = null

  let wordTable: string = ''
  $:if (currentWord) {
    wordTable = createWordTableForLessons(currentWord, templates)
  }

  export function createWordTableForLessons (currentWord: LessonWord, templates: any) {
    if (!currentWord || !currentWord?.forms) return ''
    const id = currentWord.wif_template || currentWord.id_pos
    const template = templates?.[id] || ''
    return createWordTable(currentWord.forms, template)
  }

  function createWordTable (forms: WordForms[], template: string): string {
    if (!template) return ''
    if (!forms) return ''
    const missingForm = '-'

    forms.forEach((form) => {
      template = template.replace(`{{ ${form.wif_template} }}`, form.wif)
    })
    return template
      .replace(/@@\.([a-z0-9A-Z]+)/g, (match: string, group: string) => $_('@.' + group))
      .replace(/{{[^}]+}}/g, missingForm)
  }
</script>
{#if currentWord}
  {#if wordTable}
    <Accordion show>
      <div slot='header'>{$_('learnPage.otherForms')}</div>
      <div slot='content'>
        <div class='responsive-table'>
          {@html wordTable}
        </div>
      </div>
    </Accordion>
  {/if}
{:else if wifs?.length}
  <div class='forms'>
    <div class='titleItalic'>{$_('tools.forms')}:</div>
    <div class='responsive-table'>{@html createWordTable(wifs, templates)}</div>
  </div>
{/if}
